import {
  LocationAlarmUpdate,
  LocationAlarmUpdatesDTO,
  LocationAlarmUpdateType,
} from '@hakimo-ui/hakimo/types';
import { TimelineEvent } from '@hakimo-ui/hakimo/ui-elements';

import {
  hakimoTimelineIcon,
  viewTimelineIcon,
} from '../../../shared/components/alarm-updates/icons';
import { UpdateObject } from '../../../shared/components/alarm-updates/types';
import {
  getEventDescription,
  getTimelineIcon,
} from '../../../shared/components/alarm-updates/util';
type TimelineEventSortable = TimelineEvent & { timestamp: number };

export function getLocationAlarmUpdateEvents(
  updates: LocationAlarmUpdatesDTO
): TimelineEvent[] {
  const events: TimelineEventSortable[] = [];

  const [newAlarmAddedEvents, otherEvents] = updates.items.reduce<
    [LocationAlarmUpdate[][], LocationAlarmUpdate[]]
  >(
    (acc, update) => {
      if (update.update_type === LocationAlarmUpdateType.ADD_EVENT) {
        acc[0][acc[0].length - 1].push(update);
      } else {
        acc[1].push(update);
        acc[0].push([]);
      }
      return acc;
    },
    [[[]], []]
  );
  newAlarmAddedEvents.forEach((alarmAddedEvents) => {
    if (alarmAddedEvents.length > 0) {
      const date = new Date(
        alarmAddedEvents[alarmAddedEvents.length - 1].update_time
      );

      events.push({
        icon: hakimoTimelineIcon,
        description: `Added ${alarmAddedEvents.length} new alarm${
          alarmAddedEvents.length > 1 ? 's' : ''
        }`,
        time: date.toLocaleString(),
        timestamp: date.getTime(),
      });
    }
  });
  const [viewAlarmEvents, allOtherEvents] = otherEvents.reduce<
    [LocationAlarmUpdate[][], LocationAlarmUpdate[]]
  >(
    (acc, update) => {
      if (update.update_type === LocationAlarmUpdateType.VIEW_ALARM) {
        if (
          acc[0][acc[0].length - 1].length === 0 || // no previous view alarm
          update.user?.id === acc[0][acc[0].length - 1][0].user?.id // same user
        ) {
          acc[0][acc[0].length - 1].push(update);
        } else {
          acc[0].push([update]);
        }
      } else {
        acc[1].push(update);
        acc[0].push([]);
      }
      return acc;
    },
    [[[]], []]
  );
  viewAlarmEvents.forEach((alarmViewedEvents) => {
    if (alarmViewedEvents.length > 0) {
      const date = new Date(
        alarmViewedEvents[alarmViewedEvents.length - 1].update_time
      );
      const user = alarmViewedEvents[0].user;
      events.push({
        icon: viewTimelineIcon,
        description: `${user?.name} viewed this alarm ${
          alarmViewedEvents.length
        } time${alarmViewedEvents.length > 1 ? 's' : ''}`,
        time: date.toLocaleString(),
        timestamp: date.getTime(),
      });
    }
  });
  events.push(
    ...allOtherEvents.map<TimelineEventSortable>((update) => {
      const updateObj: UpdateObject = {
        user: update.user,
        status: update.update_status,
        event: update.update_type,
        comment: update.update_text,
      };

      const date = new Date(update.update_time);

      return {
        icon: getTimelineIcon(updateObj),
        description: getEventDescription(updateObj, true),
        time: date.toLocaleString(),
        timestamp: date.getTime(),
        audio_url: update.audio_url,
      };
    })
  );

  return events
    .sort((a, b) => b.timestamp - a.timestamp)
    .map((e) => ({
      icon: e.icon,
      description: e.description,
      time: e.time,
      audio_url: e.audio_url,
    }));
}
