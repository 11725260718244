import { useCameras } from '@hakimo-ui/hakimo/data-access';
import { ListView } from '@hakimo-ui/hakimo/feature-alarms';
import { CamFilterValues, Camera } from '@hakimo-ui/hakimo/types';
import { DraggableSplitView } from '@hakimo-ui/hakimo/ui-elements';
import { FilterPanel } from '@hakimo-ui/hakimo/ui-filters';
import {
  trackFilters,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { CameraIcon } from '@heroicons/react/24/outline';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import CameraDetails from '../camera-details/CameraDetails';
import { filterConfigs } from '../constants';
import { camFilterAtom, pageAtom, pageSizeAtom } from '../state';
import CameraItem from './CameraItem';
import CameraListHeader from './CameraListHeader';
import {
  getNextCameraIdInList,
  getSearchParams,
  isDefaultFilter,
} from './util';

interface Props {
  tenantIds?: string[];
}

function CamerasList(props: Props) {
  const { tenantIds } = props;
  const [page, setPage] = useAtom(pageAtom);
  const [pageSize] = useAtom(pageSizeAtom);
  const [selectedCameraId, setSelectedCameraId] = useState<string>();
  const [nextCamIdInList, setNextCamIdInList] = useState<string>(); // to select next camera in the list when current camera is deleted
  const [total, setTotal] = useState(0);
  const [camFilters, setCamFilters] = useAtom(camFilterAtom);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);

  const { isFetching, isError, error, data } = useCameras(
    getSearchParams(page, pageSize, camFilters, tenantIds)
  );

  const changeSelected = useCallback(
    (camera: Camera) => {
      setSelectedCameraId(camera.id);
      setNextCamIdInList(getNextCameraIdInList(camera.id, data?.items));
    },
    [data?.items]
  );

  useEffect(() => {
    if (data) {
      const { total: t } = data;
      setTotal(t);
    }
  }, [data]);

  const onApplyFilters = (values: CamFilterValues) => {
    setCamFilters(values);
    trackFilters(values);
    setPage(1);
    setOpenFilterPanel(false);
  };

  const onDeletedCamera = () => {
    setSelectedCameraId(nextCamIdInList);
    setNextCamIdInList(getNextCameraIdInList(nextCamIdInList, data?.items));
  };

  const selectedCameraType = data?.items.find(
    (item) => item.id === selectedCameraId
  )?.integrationType;

  return (
    <>
      <DraggableSplitView initialLeftWidth={360}>
        <>
          {isError && (
            <div className="mb-2">
              <Alert type="error">{error.message}</Alert>
            </div>
          )}
          <CameraListHeader
            onClickOpenFilterPanel={() => setOpenFilterPanel(true)}
            isDefault={isDefaultFilter(camFilters)}
            isLoading={isFetching}
          />
          <div className="py-4 pr-4 pl-1">
            <ListView
              items={data?.items || []}
              total={total}
              page={page}
              pageSize={pageSize}
              onChangePage={setPage}
              renderListItem={(camera) => (
                <CameraItem
                  key={camera.id}
                  camera={camera}
                  selected={camera.id === selectedCameraId}
                  onClick={() => changeSelected(camera)}
                />
              )}
            />
          </div>
        </>
        {selectedCameraId ? (
          <CameraDetails
            key={selectedCameraId}
            camId={selectedCameraId}
            camType={selectedCameraType}
            onDeletedCamera={onDeletedCamera}
          />
        ) : (
          <div className="text-onlight-text-3 dark:text-ondark-text-3 border-onlight-line-2 dark:border-ondark-line-2 ml-4 flex h-full flex-col items-center justify-center border-2 border-dashed">
            <CameraIcon className="text-onlight-text-3 dark:text-ondark-text-3 h-8 w-8" />
            <div>Select a camera to check its details</div>
          </div>
        )}
      </DraggableSplitView>
      <FilterPanel
        open={openFilterPanel}
        title="Camera Filters"
        filterConfigs={filterConfigs}
        initialFilterValues={camFilters}
        trackingEventNameApply="apply_cam_filters"
        trackingEventNameReset="reset_cam_filters"
        onClose={() => setOpenFilterPanel(false)}
        onApply={onApplyFilters}
      />
    </>
  );
}

export default withAuthz(withErrorBoundary(CamerasList), ['camera:view']);
