import { useLocationAlarmUpdates } from '@hakimo-ui/hakimo/data-access';
import { QueryResult, Timeline } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { getLocationAlarmUpdateEvents } from './util';

const REFETCH_INTERVAL = 60000;

export interface Props {
  locationAlarmId: string;
}

export function AlarmUpdates(props: Props) {
  const { locationAlarmId } = props;
  const query = useLocationAlarmUpdates(locationAlarmId, REFETCH_INTERVAL);

  return (
    <div className="space-y-1">
      <h2 className="flex h-9 items-center">Alarm Updates</h2>
      <QueryResult queryResult={query} loadingText="">
        {(data) => {
          const events = getLocationAlarmUpdateEvents(data);
          return (
            <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 max-h-[30rem] overflow-auto">
              {events.length > 0 ? (
                <Timeline events={events} />
              ) : (
                <div className="p-6">
                  <Alert>No updates</Alert>
                </div>
              )}
            </div>
          );
        }}
      </QueryResult>
    </div>
  );
}

export default AlarmUpdates;
