import ContactSMS from './ContactSMS';
import { VoiceCall } from './VoiceCall';
interface Props {
  toNumber: string;
  toName: string;
}

function ContactConnect(props: Props) {
  const { toNumber, toName } = props;

  return (
    <div className="flex items-center gap-2 py-4 text-sm">
      <span className="flex flex-1 flex-col">
        <span>{toName}</span>
        <span className="text-xs">{toNumber}</span>
      </span>
      <VoiceCall toNumber={toNumber} toName={toName} />
      <ContactSMS toNumber={toNumber} toName={toName} />
    </div>
  );
}

export default ContactConnect;
