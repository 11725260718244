import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { CallModal } from './CallModal';

interface Props {
  toNumber: string;
  toName: string;
}

export function VoiceCall(props: Props) {
  const [showCallModal, setShowCallModal] = useState(false);

  const onClick = () => setShowCallModal(true);
  const onClose = () => setShowCallModal(false);

  return (
    <>
      <Button
        onClick={onClick}
        onSideEffect={eventTracker('call_location_contact')}
      >
        <PhoneIcon className="mr-2 h-4 w-4" />
        Call
      </Button>
      {showCallModal && <CallModal onCloseCb={onClose} {...props} />}
    </>
  );
}
