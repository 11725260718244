export async function streamToBlob(
  audioStream: ReadableStream<Uint8Array>
): Promise<Blob> {
  const chunks: Uint8Array[] = [];

  const reader = audioStream.getReader();
  let isReading = true;

  while (isReading) {
    const { done, value } = await reader.read();
    if (done) {
      isReading = false;
      break;
    }
    chunks.push(value);
  }

  return new Blob(chunks, { type: 'audio/mpeg' }); // MIME type as needed
}
