import { useUpdateLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarm, StatusType } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { useEffect, useState } from 'react';
import {
  mapStatusToStatusType,
  mapStatusTypeToLabelType,
} from '../../../shared/util/common';
import StatusAction from './status-action/StatusAction';
import StatusStepper from './status-stepper/StatusStepper';

interface Props {
  alarm: LocationAlarm;
  isEdit: boolean;
  onChangeEditStatus: (status: boolean) => void;
  onStatusChange?: (status: StatusType) => void;
}

export function AlarmStatus(props: Props) {
  const { alarm, onStatusChange, onChangeEditStatus, isEdit } = props;
  const [statusType, setStatusType] = useState(
    mapStatusToStatusType(alarm.status)
  );
  const labelType = mapStatusTypeToLabelType(statusType);

  useEffect(() => {
    setStatusType(mapStatusToStatusType(alarm.status));
  }, [alarm.status]);

  const mutation = useUpdateLocationAlarm(alarm.id, () => {
    onChangeEditStatus(false);
    toast('Alarm status updated', { type: 'success' });
    onStatusChange && onStatusChange(statusType);
  });

  const onChangeStatusType = (type: StatusType) => {
    setStatusType(type);
    onChangeEditStatus(true);
  };

  const onClose = () => {
    onChangeEditStatus(false);
    setStatusType(mapStatusToStatusType(alarm.status));
  };

  const onSubmit = (comment: string) => {
    mutation.mutate({ status: statusType, comment });
  };

  return (
    <div className="flex items-center">
      {isEdit ? (
        <StatusStepper onSubmit={onSubmit} onClose={onClose} />
      ) : (
        <StatusAction
          statusType={statusType}
          labelType={labelType}
          onChange={onChangeStatusType}
        />
      )}
    </div>
  );
}

export default AlarmStatus;
