import { Button } from '@hakimo-ui/shared/ui-base';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { LocationAlarmContacts } from '../../location-alarms/alarm-overview/location-alarm-contacts/LocationAlarmContacts';
import LocationFloorplan from '../../location-alarms/alarm-overview/location-floorplan/LocationFloorplan';
import SOP from '../../location-alarms/alarm-overview/sop/SOP';
import QuickActionsIcons from './QuickActionsIcons';
import QuickActionsTour from './QuickActionsTour';
import { QuickActionsId } from './util';

interface Props {
  locationId: string;
}

export function QuickActionsPanel(props: Props) {
  const { locationId } = props;
  const [selectedAction, setSelectedAction] = useState<
    QuickActionsId | undefined
  >(QuickActionsId.SOP);

  const onClose = () => setSelectedAction(undefined);

  return (
    <div className="quick-actions-panel flex max-h-[85vh] translate-x-4 gap-1">
      <QuickActionsIcons
        selected={selectedAction}
        onChange={setSelectedAction}
      />

      <Transition.Root show={selectedAction !== undefined}>
        <div className="z-10 hidden lg:block lg:w-[23vw]">
          <Transition.Child
            as={Fragment}
            enter="transition-transform ease-in duration-500"
            enterFrom="translate-x-3/4"
            enterTo="translate-x-0"
            leave="transition-transform ease-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-3/4"
          >
            <div className="relative max-h-[85vh] overflow-auto rounded p-2 lg:w-[23vw] lg:border-r lg:border-gray-200 lg:bg-gray-100 dark:lg:border-gray-800 lg:dark:bg-gray-800">
              <Button
                variant="icon"
                onClick={onClose}
                title="Close"
                classNames="absolute top-3 right-2 z-20"
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
              {selectedAction === QuickActionsId.SOP && (
                <SOP locationId={locationId} />
              )}
              {selectedAction === QuickActionsId.FLOORPLAN && (
                <LocationFloorplan locationId={locationId} />
              )}
              {selectedAction === QuickActionsId.LOCATION_CONTACTS && (
                <LocationAlarmContacts locationId={parseInt(locationId)} />
              )}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <QuickActionsTour />
    </div>
  );
}

export default QuickActionsPanel;
