import { Alarm } from '@hakimo-ui/hakimo/types';
import { DescriptionListItem } from '@hakimo-ui/hakimo/ui-elements';

export const alarmProperties = [
  'True Alarm Probability',
  'Alarm Type',
  'Alarm Time',
  'Source Entity',
  'Employee',
  'Employee Phone Number',
  'Location',
  'Alarm Local Time',
  'Alarm Source System',
  'Resolved At',
  'Time To Remediation (Seconds)',
  'Camera',
  'Standard Operating Procedure',
  'Access Card Number',
];

export function buildDLItems(alarm: Alarm): DescriptionListItem[] {
  return alarmProperties
    .map((name) => {
      return {
        name,
        value: getDLItemValue(alarm, name),
      };
    })
    .filter((item) => item.value);
}

function getDLItemValue(
  alarm: Alarm,
  name: string
): string | number | undefined {
  switch (name) {
    case 'True Alarm Probability':
      return alarm.tap ?? '-';
    case 'Alarm Time':
      return new Date(alarm.timestamp).toLocaleString();
    case 'Alarm Local Time':
      if (alarm.localTime) {
        return new Date(alarm.localTime).toLocaleString();
      }
      return;
    case 'Time To Remediation (Seconds)':
      return alarm.timeToRemediationInSeconds;
    case 'Resolved At':
      if (alarm.resolvedAt) {
        return new Date(alarm.resolvedAt).toLocaleString();
      }
      return;
    case 'Location':
      if (alarm.location?.name && alarm.location?.description) {
        return `${alarm.location?.name}\n${alarm.location?.description}`;
      } else if (alarm.location?.name) {
        return alarm.location?.name;
      }
      return undefined;
    case 'Source Entity':
      return alarm.sourceEntity?.name;
    case 'Alarm Type':
      return alarm.type;
    case 'Alarm Source System':
      return alarm.sourceSystem;
    case 'Camera':
      return alarm.camera;
    case 'Employee':
      return alarm.employee?.name;
    case 'Employee Phone Number':
      return alarm.employee?.phoneNumber;
    case 'Standard Operating Procedure':
      return alarm.sop;
    case 'Access Card Number':
      return alarm.metadata?.badgeNumber;
    default:
      return;
  }
}
