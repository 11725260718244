import { Selectable } from '@hakimo-ui/shared/ui-base';
import { CameraDetailViews } from '../constants';

export const viewItems: Selectable<string, CameraDetailViews>[] = [
  {
    id: CameraDetailViews.SPECIAL_ZONES,
    name: 'Special Zones',
  },
  {
    id: CameraDetailViews.LIVE,
    name: 'Live',
  },
  {
    id: CameraDetailViews.PLAYBACK,
    name: 'Playback',
  },
  {
    id: CameraDetailViews.SETTINGS,
    name: 'Settings',
  },
];
