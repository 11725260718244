import { CameraDetail } from '@hakimo-ui/hakimo/types';
import {
  useCanUpdateArmedStatus,
  useCanUpdateCamera,
} from '@hakimo-ui/hakimo/util';
import { useState } from 'react';
import { SettingFieldText } from './SettingFieldText';
import { SettingFieldToggle } from './SettingFieldToggle';
import { SettingSection } from './SettingSection';
import { UpdateArmedStatus } from './update-fields/update-armed-status/UpdateArmedStatus';
// import { useUpdateMotionDetection } from './update-fields/useUpdateMotionDetection';
import { useUpdatePlayback } from './update-fields/useUpdatePlayback';

interface Props {
  camera: CameraDetail;
}

export function SettingSectionActions(props: Props) {
  const { camera } = props;
  const [updateArmedStatus, setUpdateArmedStatus] = useState(false);
  const canUpdateArmedStatus = useCanUpdateArmedStatus();
  const canUpdateCamera = useCanUpdateCamera();

  // const [motionDetectionEnabled, toggleMotionDetection] =
  //   useUpdateMotionDetection(
  //     camera.id,
  //     camera.isMotionDetectionEnabled ?? false
  //   );

  const [playbackEnabled, togglePlayback] = useUpdatePlayback(
    camera.id,
    camera.isPlaybackEnabled ?? false
  );

  const cameraStatus = camera.armStatus.endTimeText ? (
    camera.armStatus.endTimeText
  ) : camera.armStatus.isArmed ? (
    <span className="text-status-green">Armed</span>
  ) : (
    <span className="text-status-red">Disarmed</span>
  );

  return (
    <>
      <SettingSection name="Actions">
        {/* {canUpdateCamera ? (
          <SettingFieldToggle
            name="Motion Detection"
            value={motionDetectionEnabled}
            onToggle={toggleMotionDetection}
          />
        ) : null} */}
        {canUpdateCamera ? (
          <SettingFieldToggle
            name="Playback"
            value={playbackEnabled}
            onToggle={togglePlayback}
          />
        ) : null}
        {canUpdateArmedStatus ? (
          <SettingFieldText
            name="Status"
            value={cameraStatus}
            onClickUpdate={() => setUpdateArmedStatus(true)}
          />
        ) : null}
      </SettingSection>
      {updateArmedStatus && (
        <UpdateArmedStatus
          cameraId={camera.id}
          isArmed={camera.armStatus.isArmed}
          onClose={() => setUpdateArmedStatus(false)}
        />
      )}
    </>
  );
}
