import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import { LiveMse, LiveWebRtc } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import LiveHls from './LiveHls';
import { Camera } from './types';

interface Props {
  camera: Camera;
  controlsList?: VideoControlsList[];
  lockAspectRatio?: boolean;
}

export function LiveViewSwitch(props: Props) {
  const { camera, controlsList, lockAspectRatio = false } = props;

  const hlsLiveView = (
    <LiveHls
      cameraId={camera.id}
      renderAlert={renderAlert}
      open
      lockAspectRatio={lockAspectRatio}
      controlsList={controlsList}
    />
  );

  return camera.livestreamUrl ? (
    camera.livestreamUrl.startsWith('ws') ? (
      <LiveMse mseUrl={camera.livestreamUrl} lockAspectRatio={true} />
    ) : camera.livestreamUrl.includes('live.hakimo.ai') ? (
      <LiveWebRtc livestreamUrl={camera.livestreamUrl} />
    ) : (
      hlsLiveView
    )
  ) : (
    hlsLiveView
  );
}

function renderAlert(message: string) {
  return <Alert>{message}</Alert>;
}
