import clsx from 'clsx';
import { TIMELINE_HEIGHT } from './constants';

export interface Props {
  x1: number;
  x2: number;
  selected: boolean;
  onClick: () => void;
}

export function Event(props: Props) {
  const { x1, x2, selected, onClick } = props;
  return (
    <rect
      className={clsx(
        'dark:focus-visible:outline-ondark-primary focus-visible:outline-onlight-primary outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        selected
          ? 'dark:fill-ondark-primary fill-onlight-primary'
          : 'fill-status-gray cursor-pointer opacity-40 transition-opacity hover:opacity-70',
        'animate-highlight dark:animate-dark-highlight'
      )}
      x={x1}
      y={0}
      width={x2 - x1}
      height={TIMELINE_HEIGHT}
      pointerEvents="bounding-box"
      rx="3"
      tabIndex={selected ? -1 : 0}
      onClick={!selected ? onClick : undefined}
    />
  );
}

export default Event;
