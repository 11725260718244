import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { DetectionType } from './util';

interface Props {
  onCancel: () => void;
  onSubmit: (detectionType: DetectionType) => void;
}

function MotionDetector({ onCancel, onSubmit }: Props) {
  const onDetection = (type: DetectionType) => () => onSubmit(type);

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-4 whitespace-nowrap">
        <span>What did you see?</span>
        <div className="flex gap-4">
          <Button
            onClick={onDetection(DetectionType.PERSON)}
            title="Person"
            variant="outline"
            onSideEffect={eventTracker('person_detected')}
            autoFocus
          >
            Person
          </Button>
          <Button
            onClick={onDetection(DetectionType.VEHICLE)}
            title="Vehicle"
            onSideEffect={eventTracker('vehicle_detected')}
          >
            Vehicle
          </Button>
          <Button
            onClick={onDetection(DetectionType.INCORRECT_DETECTION)}
            title="Incorrect Detection"
            onSideEffect={eventTracker('incorrect_detection')}
          >
            Incorrect Detection
          </Button>
        </div>
      </div>
      <div className="ml-16">
        <Button
          title="Cancel"
          variant="icon"
          onClick={onCancel}
          onSideEffect={eventTracker('cancel_status_stepper')}
        >
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default MotionDetector;
