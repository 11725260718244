import {
  AlarmMedia,
  Box,
  BoxType,
  Point,
  Polygon,
} from '@hakimo-ui/hakimo/types';
import { deepEqual } from '@hakimo-ui/hakimo/util';
import { Props } from './AlarmOverview';
import { TimelineSource } from './event-timeline/types';

export function eventsDomain(sources: TimelineSource[]) {
  const timeStamps = sources.reduce<number[]>((acc, cur) => {
    return [...acc, ...flattenArray(cur.events.map((e) => e.timespan))];
  }, []);

  const min = Math.min(...timeStamps);
  const max = Math.max(...timeStamps);

  return [min, max];
}

export const flattenArray = <T>(arr: T[][]) =>
  arr.reduce<T[]>((acc, val) => acc.concat(val), []);

export function getAlarmTime(data?: AlarmMedia) {
  const markerAlarmTime = data?.videoDetails?.markers.find(
    (m) => m.label === 'Alarm Time'
  );
  return markerAlarmTime?.timestamp ?? 0;
}

function getTagTextFromBoxType(type?: BoxType) {
  switch (type) {
    case BoxType.PERSON:
      return 'Person';
    case BoxType.VEHICLE:
      return 'Vehicle';
    default:
      return '';
  }
}

export const getVideoPolygons = (boxes: Box[]): Polygon[] => {
  return boxes.map((box) => {
    const [left, top, width, height] = box.coords;
    const points: Point[] = [
      [left, top],
      [left + width, top],
      [left + width, top + height],
      [left, top + height],
    ];
    const polygon: Polygon = {
      color: box.type === BoxType.VEHICLE ? 'red' : 'yellow',
      pointSize: 'small',
      closed: true,
      points,
      tag: getTagTextFromBoxType(box.type),
    };
    return polygon;
  });
};

export function arePropsEqual(oldProps: Props, newProps: Props) {
  return (
    oldProps.onStatusChange === newProps.onStatusChange &&
    deepEqual(oldProps.alarm, newProps.alarm)
  );
}
