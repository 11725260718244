/* eslint-disable max-lines */
import {
  Alarm,
  AlarmStatus,
  FilterValues,
  LocationAlarmStatus,
  StatusType,
  SystemNotificationPreference,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import {
  DEFAULT_AUDIO_NOTIF_FILE,
  dismissToast,
  toast,
  triggerSystemNotification,
} from '@hakimo-ui/hakimo/util';
import { LabelType } from '@hakimo-ui/shared/ui-base';
import { NEW_ALARM_TOAST_ID } from '../constants';
import { CommonAlarmType } from '../types';

export function mapStatusTypeToLabelType(statusType: StatusType): LabelType {
  switch (statusType) {
    case StatusType.RESOLVED:
      return 'success';
    case StatusType.IN_PROGRESS:
      return 'warning';
    case StatusType.PENDING:
      return 'error';
  }
}

export function mapStatusToLabelType(
  status: AlarmStatus | LocationAlarmStatus
): LabelType {
  switch (status) {
    case 'Resolved Manually':
    case 'Resolved by Hakimo':
    case 'Alarm Acknowledged via ACS':
    case 'Resolved':
      return 'success';
    case 'Analyzing':
      return 'info';
    case 'In Progress':
      return 'warning';
    case 'Pending':
      return 'error';
    default:
      return 'default';
  }
}

export function mapStatusToStatusType(
  status: AlarmStatus | LocationAlarmStatus
): StatusType {
  switch (status) {
    case 'Resolved by Hakimo':
    case 'Resolved Manually':
    case 'Alarm Acknowledged via ACS':
    case 'Resolved':
      return StatusType.RESOLVED;
    case 'Analyzing':
    case 'Pending':
      return StatusType.PENDING;
    case 'In Progress':
      return StatusType.IN_PROGRESS;
  }
}

export function isNotDefaultFilter(filters: Partial<FilterValues>): boolean {
  return (
    (filters.location && filters.location.values.length > 0) ||
    (filters.sourceSystem && filters.sourceSystem.length > 0) ||
    (filters.sourceEntity && filters.sourceEntity.values.length > 0) ||
    (filters.type && filters.type.values.length > 0) ||
    (filters.employee && filters.employee.values.length > 0) ||
    (filters.status && filters.status.values.length > 0) ||
    (filters.tenant && filters.tenant.values.length > 0) ||
    (filters.time && filters.time.period !== TimePeriod.ALL_TIME) ||
    false
  );
}

export function notifyNewAlarm(
  prevData?: CommonAlarmType[],
  newData?: CommonAlarmType[],
  page?: number,
  type?: string,
  audio?: boolean,
  systemNotifyPreference?: SystemNotificationPreference
) {
  if (
    // old data had no pending
    unresolvedAlarmCount(prevData) === 0 &&
    // new data has unresolved alarm
    unresolvedAlarmCount(newData) > 0 &&
    // on first page
    page === 1
  ) {
    const notifAudioFileName = getNotifAudioFileName(type, newData);
    triggerSystemNotification(
      systemNotifyPreference,
      getNewAlarmNotificationText(type),
      notifAudioFileName
    );
    toast(getNewAlarmNotificationText(type), {
      audio,
      duration: Infinity,
      id: NEW_ALARM_TOAST_ID,
      audioFileName: notifAudioFileName,
    });
  } else if (unresolvedAlarmCount(newData) === 0) {
    dismissToast(NEW_ALARM_TOAST_ID);
  }
}

export function getNewAlarmNotificationText(type?: string) {
  if (type === 'locationAlarm') {
    return 'New unresolved location alarm';
  }
  return 'New unresolved alarm';
}

function unresolvedAlarmCount(alarms?: CommonAlarmType[]): number {
  if (!alarms) {
    return 0;
  }
  return alarms.filter(
    (item: CommonAlarmType) =>
      item.tap &&
      item.tap > 50 &&
      mapStatusToStatusType(item.status) !== 'Resolved'
  ).length;
}

// this is deliberately hardcoded as requested by godaddy customer
function getNotifAudioFromAlarmType(type: string) {
  switch (type) {
    case 'Badge Unauthorised':
    case 'Invalid Badge':
    case 'Inactive Badge':
      return 'notif-sound-1';
    case 'Critical - Duress':
    case 'Access Denied Under Duress':
    case 'Access Granted Under Duress':
      return 'notif-sound-2';
    case 'Critical - AED Active':
      return 'notif-sound-3';
    case 'Critical - Secure Environment':
      return 'notif-sound-4';
    case 'Invalid PIN Number':
      return 'notif-sound-5';
    default:
      return DEFAULT_AUDIO_NOTIF_FILE;
  }
}

function getNotifAudioFileName(type?: string, alarms?: CommonAlarmType[]) {
  let audioFileName = 'notif-sound-default';
  if (type === 'alarm' && alarms && alarms.length > 0) {
    const newAlarm = alarms.filter(
      (item: CommonAlarmType) =>
        item.tap &&
        item.tap > 50 &&
        mapStatusToStatusType(item.status) !== 'Resolved'
    )[0] as Alarm;
    const alarmType = newAlarm.type;
    audioFileName = getNotifAudioFromAlarmType(alarmType);
  }
  return audioFileName;
}
