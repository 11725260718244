import {
  NavItem,
  RootHeader,
  SidebarMobile,
} from '@hakimo-ui/hakimo/ui-navigation';
import { ReactElement, useState } from 'react';

interface Props {
  children: ReactElement;
  navItems: NavItem[];
}

export function RootLayout(props: Props) {
  const { children, navItems } = props;
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false);

  const handleExpandSidebar = () => {
    setSidebarMobileOpen(true);
  };

  return (
    <div className="dark:bg-dark-bg h-full dark:text-white">
      <SidebarMobile
        open={sidebarMobileOpen}
        navItems={navItems}
        onClose={() => setSidebarMobileOpen(false)}
      />
      <div className="flex flex-col lg:h-full" data-testid="page-container">
        <RootHeader expandSidebar={handleExpandSidebar} />
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
}

export default RootLayout;
