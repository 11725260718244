import { TIMELINE_HEIGHT, TIMELINE_V_SPACE } from './constants';
import Event from './Event';
import { TimelineSource } from './types';

interface Props {
  sources: TimelineSource[];
  selected: string;
  map: (x: number) => number;
  onChangeSelected: (eventId: string) => void;
}

export function Events(props: Props) {
  const { sources, selected, map, onChangeSelected } = props;

  return (
    <g className="timelines">
      {sources.map((source, i) => (
        <g
          key={source.id}
          className="timeline"
          transform={`translate(0, ${
            (TIMELINE_HEIGHT + TIMELINE_V_SPACE) * i
          })`}
        >
          {source.events.map((event) => {
            const {
              timespan: [s, e],
            } = event;

            const x1 = map(s);
            const x2 = map(e);

            return (
              <Event
                key={event.id}
                selected={selected === event.id}
                x1={x1}
                x2={x2}
                onClick={() => onChangeSelected(event.id)}
              />
            );
          })}
        </g>
      ))}
    </g>
  );
}
