import {
  ContactsIcon,
  FloorPlanIcon,
  SOPIcon,
} from '@hakimo-ui/hakimo/ui-elements';

export enum QuickActionsId {
  SOP = 'sop',
  FLOORPLAN = 'floorplan',
  LOCATION_CONTACTS = 'locationContacts',
}

export const QuickActions = [
  {
    id: QuickActionsId.SOP,
    title: 'Standard Operating Procedure (SOP)',
    icon: {
      component: SOPIcon,
      className: 'h-10 w-10 sop-quick-action',
    },
  },
  {
    id: QuickActionsId.FLOORPLAN,
    title: 'Floor Plan',
    icon: {
      component: FloorPlanIcon,
      className: 'h-10 w-10 floorplan-quick-action',
    },
  },
  {
    id: QuickActionsId.LOCATION_CONTACTS,
    title: 'Contacts',
    icon: {
      component: ContactsIcon,
      className: 'h-7 w-7 contacts-quick-action',
    },
  },
];
