import { User } from '@hakimo-ui/hakimo/types';
import { Selectable } from '@hakimo-ui/shared/ui-base';

export function getTenantFilterItems(user: User): Selectable[] {
  const items = [user.tenant];

  if (user.mspTenants) {
    user.mspTenants.forEach((mspTenant) => {
      if (mspTenant.id !== user.tenant.id) {
        items.push(mspTenant);
      }
    });
  }

  return items;
}
