import { useState } from 'react';
import CommentDropdown from './CommentDropdown';
import MotionDetector from './MotionDetector';
import { DetectionType, StepperState, getCommentPrefix } from './util';

interface Props {
  onSubmit: (comment: string) => void;
  onClose: () => void;
}

export function StatusStepper(props: Props) {
  const { onSubmit, onClose } = props;
  const [stepperState, setStepperState] = useState<StepperState>({
    step: 0,
    comment: '',
    detectionType: undefined,
  });

  const onDetection = (detectionType: DetectionType) => {
    setStepperState({
      ...stepperState,
      step: 1,
      detectionType,
    });
  };

  const onSubmitComment = (comment: string) => {
    const commentPrefix = getCommentPrefix(stepperState.detectionType);
    onSubmit(`${commentPrefix} - ${comment}`);
  };

  return (
    <div>
      {stepperState.step === 0 && (
        <MotionDetector onCancel={onClose} onSubmit={onDetection} />
      )}
      {stepperState.step > 0 && stepperState.detectionType && (
        <CommentDropdown
          detectionType={stepperState.detectionType}
          onCancel={onClose}
          onSubmit={onSubmitComment}
        />
      )}
    </div>
  );
}

export default StatusStepper;
