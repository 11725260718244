/* eslint-disable max-lines */
import { useUser } from '../contexts/user-profile';
import { hasPermission } from './utils';

export function useCanAddAlarmComment() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:add-comment');
}

export function useCanAddAlarmFeedback() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:add-feedback');
}

export function useCanChangeAlarmStatus() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:change-status');
}

export function useCanUpdateDcp() {
  const user = useUser();
  return hasPermission(user, 'dcp/details:update');
}

export function useCanAddDcp() {
  const user = useUser();
  return hasPermission(user, 'dcp:add');
}

export function useCanDebugMedia() {
  const user = useUser();
  return hasPermission(user, 'alarm/debug-media:view');
}

export function useCanViewLiveCamera() {
  const user = useUser();
  return hasPermission(user, 'camera/live:view');
}

export function useCanReportBug() {
  const user = useUser();
  return hasPermission(user, 'alarm/bug-report:create');
}

export function useCanAddLocation() {
  const user = useUser();
  return hasPermission(user, 'location:create');
}

export function useCanAddDoorGroup() {
  const user = useUser();
  return hasPermission(user, 'door_group:create');
}

export function useCanUpdateDoorsToDoorGroup() {
  const user = useUser();
  return hasPermission(user, 'door/group:update');
}

export function useCanAddDoorsToLocation() {
  const user = useUser();
  return hasPermission(user, 'door/location:update');
}

export function useCanSeeDcpDetails() {
  const user = useUser();
  return hasPermission(user, 'dcp/details:view');
}

export function useCanRemoveMapping() {
  const user = useUser();
  return hasPermission(user, 'dcp:delete');
}

export function useCanViewTenantColumn() {
  const user = useUser();
  return hasPermission(user, 'multi-tenant:view');
}

export function useCanChangeStatusNoComment() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:change-status-no-comment');
}

export function useCanVoiceCall() {
  const user = useUser();
  return hasPermission(user, 'voice-call');
}

export function useCanAddUser() {
  const user = useUser();
  return hasPermission(user, 'user:add');
}

export function useCanUpdateUser() {
  const user = useUser();
  return hasPermission(user, 'user:update');
}

export function useCanUpdateDeadZones() {
  const user = useUser();
  return hasPermission(user, 'camera:update/deadzone');
}

export function useCanUpdateVehicleParkingZone() {
  const user = useUser();
  return hasPermission(user, 'camera:update/vehicleparkingzone');
}

export function useCanUpdateActiveZone() {
  const user = useUser();
  return hasPermission(user, 'camera:update/activezone');
}

export function useCanModifyAudioDevice() {
  const user = useUser();
  return hasPermission(user, 'audio_devices:modify');
}

export function useCanUpdateLocationAlarmStatus() {
  const user = useUser();
  return hasPermission(user, 'location_alarm:update');
}

export function useCanShareLocationAlarm() {
  const user = useUser();
  return hasPermission(user, 'location_alarm/details:share');
}

export function useCanModifyAudioDeviceCamMap() {
  const user = useUser();
  return hasPermission(user, 'audio_devices/mappings:modify');
}

export function useCanUpdateTenantConfig() {
  const user = useUser();
  return hasPermission(user, 'tenant_config:update');
}

export function useCanUpdateArmedStatus() {
  const user = useUser();
  return hasPermission(user, 'camera:update/armed');
}

export function useCanUpdateCamera() {
  const user = useUser();
  return hasPermission(user, 'camera:update');
}

export function useCanAddCamera() {
  const user = useUser();
  return hasPermission(user, 'camera:add');
}

export function useCanViewLocationAlarm() {
  const user = useUser();
  return hasPermission(user, 'location_alarm:view');
}

export function useCanUpdateSOP() {
  const user = useUser();
  return hasPermission(user, 'sop:update');
}
