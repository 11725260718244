import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useTwilioToken(onSuccess?: (token: string) => void) {
  const url = `/v2/orm/voice/twilio/token`;
  const request = new Request(url);

  return useAuthenticatedRequest<string>(request, {
    queryKey: ['voice-twilio-token'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.token;
    },
    onSuccess,
    cacheTime: 0,
    refetchInterval: 55 * 60 * 1000, // 5 mins before token expires
  });
}

export default useTwilioToken;
