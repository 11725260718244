import { Selectable } from '@hakimo-ui/shared/ui-base';
import { MotionAlarm, StatusType } from './alarms/alarms';
import { Location } from './location';

export interface LocationAlarmsDTO {
  total: number;
  items: LocationAlarm[];
}

export interface LocationAlarm {
  id: string;
  guid: string;
  alarmTime: string;
  status: LocationAlarmStatus;
  location: Location;
  tap: number;
  rawAlarms: MotionAlarm[];
}
export const locationAlarmStatuses = [
  'Analyzing',
  'Pending',
  'Resolved',
  'In Progress',
] as const;

export type LocationAlarmStatus = typeof locationAlarmStatuses[number];

export interface LocationAlarmUpdateRequestDTO {
  comment?: string;
  status?: StatusType;
}

export interface LocationAlarmUpdatesDTO {
  items: LocationAlarmUpdate[];
}

export interface LocationAlarmUpdate {
  id: number;
  update_status: LocationAlarmStatus;
  update_text: string;
  update_time: string;
  update_type: LocationAlarmUpdateType;
  user?: Selectable;
  audio_url?: string;
}

export enum LocationAlarmUpdateType {
  ADD_EVENT = 'Add new event',
  CHANGE_STATUS = 'Change Status',
  UPDATE_TAP = 'Update TAP',
  ADD_COMMENT = 'Add comment',
  VIEW_ALARM = 'View alarm',
  MANUAL_TALKDOWN = 'Manual audio talkdown',
}
