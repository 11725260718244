import {
  AlarmUpdatesDTO,
  LocationAlarmUpdateType,
} from '@hakimo-ui/hakimo/types';
import { TimelineEvent } from '@hakimo-ui/hakimo/ui-elements';
import { mapStatusToStatusType } from '../../util/common';
import {
  checkTimelineIcon,
  commentTimelineIcon,
  hakimoTimelineIcon,
  inProgressTimelineIcon,
  pendingTimelineIcon,
  speakerTalkdownTimelineIcon,
  viewTimelineIcon,
} from './icons';
import { UpdateObject } from './types';

const HAKIMO_USERNAME = 'Hakimo AI Engine';
const ADD_COMMENT_EVENT = 'Added a comment';
const ACS_ACKNOWLEDGMENT_EVENT = 'ACS Alarm Acknowledgment';
const SPEAKER_TALKDOWN_EVENT = 'Responded via speaker talk-down';

const AI_ENGINE_NAME = 'Hakimo AI Engine';
export function getAlarmUpdatesSearchParams(): string {
  const params = new URLSearchParams();
  return params.toString();
}

export function getTimelineIcon(update: UpdateObject): JSX.Element {
  if (update.user?.name === HAKIMO_USERNAME || update.user === null) {
    return hakimoTimelineIcon;
  }
  if (update.status) {
    switch (mapStatusToStatusType(update.status)) {
      case 'Resolved':
        return checkTimelineIcon;
      case 'In Progress':
        return inProgressTimelineIcon;
      case 'Pending':
        return pendingTimelineIcon;
    }
  }
  if (
    update.event === SPEAKER_TALKDOWN_EVENT ||
    update.event === LocationAlarmUpdateType.MANUAL_TALKDOWN
  ) {
    return speakerTalkdownTimelineIcon;
  }
  if (update.event === LocationAlarmUpdateType.VIEW_ALARM) {
    return viewTimelineIcon;
  }
  return commentTimelineIcon;
}

export function getEventDescription(
  update: UpdateObject,
  assumeHakimo = false
) {
  if (update.status) {
    let description = '';
    if (assumeHakimo) {
      description = `${update.user?.name ?? AI_ENGINE_NAME} changed status to ${
        update.status
      }`;
    } else if (update.user?.name !== undefined) {
      description = `${update.user?.name} changed status to ${update.status}`;
    } else {
      description = `Status changed to ${update.status}`;
    }
    if (update.comment) {
      description = description + `: ${update.comment}`;
    }
    return description;
  }
  if (
    update.event === ADD_COMMENT_EVENT ||
    update.event === LocationAlarmUpdateType.ADD_COMMENT
  ) {
    if (assumeHakimo) {
      return `${update.user?.name ?? AI_ENGINE_NAME}: "${update.comment}"`;
    } else if (update.user?.name !== undefined) {
      return `${update.user?.name}: "${update.comment}"`;
    }
    return `"${update.comment}"`;
  }
  if (update.event === ACS_ACKNOWLEDGMENT_EVENT) {
    return `Alarm Acknowledged via ACS: ${update.comment}`;
  }
  if (
    update.event === SPEAKER_TALKDOWN_EVENT ||
    update.event === LocationAlarmUpdateType.MANUAL_TALKDOWN
  ) {
    if (assumeHakimo) {
      return `${
        update.user?.name ?? AI_ENGINE_NAME
      } responded via speaker talk-down`;
    } else if (update.user?.name !== undefined) {
      return `${update.user?.name} responded via speaker talk-down`;
    }
    return `Speaker talk-down conducted`;
  }
  if (update.event === LocationAlarmUpdateType.VIEW_ALARM) {
    if (update.user?.name !== undefined) {
      return `${update.user?.name} viewed the alarm`;
    }
    return 'Alarm was viewed';
  }
  return `${update.user?.name} added feedback: ${update.comment}`;
}

export function getUpdateEvents(updates: AlarmUpdatesDTO): TimelineEvent[] {
  return updates.items.map((update) => ({
    icon: getTimelineIcon(update),
    description: getEventDescription(update),
    time: new Date(update.timestamp).toLocaleString(),
    audio_url: update.audio_url,
  }));
}
