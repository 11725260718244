import { useTwilioToken } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, HakimoSpinner, Modal } from '@hakimo-ui/shared/ui-base';
import { Call, Device } from '@twilio/voice-sdk';
import { useEffect, useState } from 'react';
import Keypad from './Keypad';

interface Props {
  toName: string;
  toNumber: string;
  onCloseCb: () => void;
}

export function CallModal(props: Props) {
  const { toName, toNumber, onCloseCb } = props;
  const [device, setDevice] = useState<Device>();
  const [call, setCall] = useState<Call>();
  const [twilioToken, setTwilioToken] = useState('');
  const [callInProgress, setCallInProgress] = useState(false);
  const [deviceConnected, setDeviceConnected] = useState(false);

  const onSuccess = (token: string) => setTwilioToken(token);

  useTwilioToken(onSuccess);

  useEffect(() => {
    if (!twilioToken) return;
    if (!device) {
      import('@twilio/voice-sdk').then((twilio) => {
        setDevice(
          new twilio.Device(twilioToken, {
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          })
        );
      });
    } else {
      device.updateToken(twilioToken);
      device?.on('error', (_, erroredCall) => {
        erroredCall?.disconnect();
      });
    }
  }, [device, setDevice, twilioToken]);

  useEffect(() => {
    if (!device || deviceConnected) return;
    if (!deviceConnected) {
      setDeviceConnected(true);
    }
    call?.disconnect();
    toast(`Calling ${toName} at ${toNumber}`);
    const connectOptions = { To: toNumber };
    setDeviceConnected(true);
    device?.connect({ params: connectOptions }).then((c) => {
      setCall(c);

      c?.on('accept', () => {
        setCallInProgress(true);
      });
      c?.on('disconnect', () => {
        setCallInProgress(false);
        toast('Call ended');
        onCloseCb();
      });
    });
  }, [call, device, deviceConnected, onCloseCb, toName, toNumber]);

  const closeAndHangup = () => {
    call?.disconnect();
    onCloseCb();
  };

  const actions = (
    <Button variant="primary" onClick={closeAndHangup}>
      Hang up
    </Button>
  );

  return (
    <Modal
      open={true}
      onClose={closeAndHangup}
      title="Voice Call"
      footer={actions}
    >
      {callInProgress ? (
        <>
          <div className="w-96 px-8 py-4">
            <Alert type="info">Call in progress</Alert>
          </div>
          <div className="w-96 px-8 py-4">
            <Keypad onKeypadButtonClick={(digit) => call?.sendDigits(digit)} />
          </div>
        </>
      ) : (
        <div className="flex h-20 w-96 items-center justify-center">
          <HakimoSpinner />
        </div>
      )}
    </Modal>
  );
}
