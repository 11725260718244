import { AppEnvironment } from './types';
import {
  asAuthMode,
  asEnvironment,
  getEnvValueAsString,
  getEnvValueAsType,
} from './util';

export const environment: AppEnvironment = {
  auth0: {
    domain: getEnvValueAsString('AUTH0_DOMAIN'),
    clientId: getEnvValueAsString('AUTH0_CLIENT_ID'),
    audience: getEnvValueAsString('AUTH0_AUDIENCE'),
  },
  release: getEnvValueAsString('RELEASE_NAME'),
  name: getEnvValueAsType('ENVIRONMENT', asEnvironment),
  authMode: getEnvValueAsType('AUTH_MODE', asAuthMode),
  amplitudeApiKey: getEnvValueAsString('AMPLITUDE_API_KEY'),
  pollyIdentityPoolId: getEnvValueAsString('POLLY_IDENTITY_POOL_ID'),
  pollyRegion: getEnvValueAsString('POLLY_REGION'),
};
