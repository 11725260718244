import { useAlarmMedia } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarm, StatusType } from '@hakimo-ui/hakimo/types';
import { withAuthz } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { memo, useMemo, useState } from 'react';
import { viewModeAtom } from '../state';
import AlarmHeader from './alarm-header/AlarmHeader';
import AlarmUpdates from './alarm-updates/AlarmUpdates';
import AlarmVideo from './alarm-video/AlarmVideo';
import EventTimeline from './event-timeline/EventTimeline';
import Header from './header/Header';
import LiveView from './live-view/LiveView';
import { LocationAlarmContacts } from './location-alarm-contacts/LocationAlarmContacts';
import LocationFloorplan from './location-floorplan/LocationFloorplan';
import SOP from './sop/SOP';
import { useTimelineSources } from './useTimelineSources';
import { useTrackAlarmEvents } from './useTrackAlarmEvents';
import { arePropsEqual, eventsDomain } from './util';

export interface Props {
  alarm: LocationAlarm;
  onStatusChange?: (status: StatusType) => void;
  sharedToken?: string;
  showSOP?: boolean;
  showContacts?: boolean;
}
export function AlarmOverview(props: Props) {
  const {
    alarm,
    onStatusChange,
    sharedToken,
    showSOP = true,
    showContacts = true,
  } = props;
  const sortedRawAlarms = useMemo(
    () =>
      alarm.rawAlarms.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [alarm]
  );
  const { onTrackResolve, onTrackTalkdown } = useTrackAlarmEvents(alarm.id);
  const latestAlarm = sortedRawAlarms[alarm.rawAlarms.length - 1];
  const [selectedEventId, setSelectedEventId] = useState(latestAlarm.id);
  const [selectedCameraId, setSelectedCameraId] = useState<string | undefined>(
    latestAlarm.sourceEntity.id
  );
  const [viewMode, setViewMode] = useAtom(viewModeAtom);
  const isShared = !!sharedToken;
  const { data } = useAlarmMedia({
    id: selectedEventId,
    boxes: true,
    sharedToken,
    isSharedLocationAlarm: isShared,
  });
  const timeLineSources = useTimelineSources(sortedRawAlarms);
  const [beginTime, endTime] = eventsDomain(timeLineSources);
  const onNextEvent = () => {
    const index = sortedRawAlarms.findIndex((a) => a.id === selectedEventId);
    if (index < sortedRawAlarms.length - 1) {
      setSelectedEventId(sortedRawAlarms[index + 1].id);
    }
  };
  const onPreviousEvent = () => {
    const index = sortedRawAlarms.findIndex((a) => a.id === selectedEventId);
    if (index > 0) {
      setSelectedEventId(sortedRawAlarms[index - 1].id);
    }
  };
  const onStatusChangeCb = (status: StatusType) => {
    onTrackResolve();
    onStatusChange && onStatusChange(status);
  };
  return (
    <div className="sticky top-0 flex h-full w-full flex-col divide-y dark:divide-white/10">
      {!isShared && (
        <Header
          alarm={alarm}
          viewMode={viewMode}
          onChangeViewMode={setViewMode}
          onStatusChange={onStatusChangeCb}
        />
      )}
      <div className="space-y-4 pt-4 pl-4 pb-4">
        <div
          className={clsx(
            'flex flex-col gap-4',
            viewMode === 'split' && !isShared && 'xl:grid xl:grid-cols-2'
          )}
        >
          <div
            className="flex-1 space-y-3"
            hidden={viewMode === 'live' && !isShared}
          >
            <AlarmHeader beginTime={beginTime} endTime={endTime} />
            <AlarmVideo
              videoPath={data?.videoPath}
              boxes={data?.boxes}
              onNext={onNextEvent}
              onPrevious={onPreviousEvent}
              hasPrevious={
                sortedRawAlarms.findIndex((a) => a.id === selectedEventId) > 0
              }
              hasNext={
                sortedRawAlarms.findIndex((a) => a.id === selectedEventId) <
                sortedRawAlarms.length - 1
              }
            />
            <EventTimeline
              sources={timeLineSources}
              selected={selectedEventId}
              liveSourceId={selectedCameraId}
              onChangeSelected={setSelectedEventId}
            />
          </div>
          {!isShared && (
            <div className="flex-1 space-y-4" hidden={viewMode === 'events'}>
              {selectedCameraId && (
                <LiveView
                  alarms={sortedRawAlarms}
                  locationAlarmId={alarm.id}
                  selectedCameraId={selectedCameraId}
                  onChangeSelectedCameraId={setSelectedCameraId}
                  onTalkdown={onTrackTalkdown}
                />
              )}
            </div>
          )}
          {!isShared && (
            <>
              <LocationFloorplan locationId={String(alarm.location.id)} />
              {showSOP && <SOP locationId={String(alarm.location.id)} />}
              {showContacts && (
                <LocationAlarmContacts locationId={alarm.location.id} />
              )}
              <AlarmUpdates locationAlarmId={alarm.id} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default memo(
  withAuthz(AlarmOverview, ['location_alarm/detail:view']),
  arePropsEqual
);
export const AlarmOverviewNoAuth = memo(AlarmOverview, arePropsEqual);
