import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import {
  Button,
  InputField,
  Label,
  Selectable,
} from '@hakimo-ui/shared/ui-base';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { DetectionType, getStatusCommentOptions } from './util';

interface Props {
  detectionType: DetectionType;
  onCancel: () => void;
  onSubmit: (comment: string) => void;
}

export function CommentDropdown(props: Props) {
  const { detectionType, onCancel, onSubmit } = props;
  const [comment, setComment] = useState<Selectable>({
    id: 'blank',
    name: '',
  });
  const [showTextField, setShowTextField] = useState<boolean>(false);

  const onSelectComment = (item: Selectable) => {
    if (item.name === 'Other') {
      setShowTextField(true);
      setComment({ id: 'blank', name: '' });
    } else {
      setComment(item);
    }
  };

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment({ id: e.target.value, name: e.target.value });
  };

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-4">
        <Label small type="info">
          {detectionType.toUpperCase()}
        </Label>
        <span>Comment:</span>
        <div className="flex gap-4">
          <div className="w-72 flex-1">
            {showTextField ? (
              <InputField
                label=""
                onChange={onTextFieldChange}
                type="text"
                value={comment?.name || ''}
                placeholder="Please specify"
                autoFocus
              />
            ) : (
              <SelectMenu
                value={comment}
                items={getStatusCommentOptions(detectionType)}
                displayValue={(item) => item?.name ?? ''}
                onChange={onSelectComment}
                id={(item) => item?.id || ''}
              />
            )}
          </div>
          <Button
            title="Confirm"
            variant="primary"
            disabled={comment.id === 'blank'}
            onClick={() => onSubmit(comment?.name ?? '')}
            onSideEffect={eventTracker('add_status_update_comment')}
          >
            Confirm
          </Button>
        </div>
      </div>
      <div className="ml-16">
        <Button
          title="Cancel"
          variant="icon"
          onClick={onCancel}
          onSideEffect={eventTracker('cancel_status_stepper')}
        >
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default CommentDropdown;
