import { SystemNotificationPreference } from '@hakimo-ui/hakimo/types';
import { TabItem } from '@hakimo-ui/shared/ui-base';
import { useLocation } from 'react-router-dom';

export type TabId = 'general' | 'preferences';

export function useActiveTabId(): TabId {
  const location = useLocation();

  switch (location.pathname) {
    case '/profile/general':
      return 'general';
    case '/profile/preferences':
      return 'preferences';
    default:
      return 'general';
  }
}

export const tabItems: TabItem<TabId>[] = [
  {
    id: 'general',
    name: 'General',
  },
  {
    id: 'preferences',
    name: 'Preferences',
  },
];

export const systemNotifOptions = [
  {
    id: SystemNotificationPreference.OFF,
    title: 'Off',
  },
  {
    id: SystemNotificationPreference.INACTIVE,
    title: 'When inactive',
  },
  {
    id: SystemNotificationPreference.ALWAYS,
    title: 'Always',
  },
];

const getBrowser = (): string | undefined => {
  const userAgent = navigator.userAgent.toLowerCase();
  let browserName;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  }
  return browserName;
};

type BrowserLinkMap = {
  [key: string]: string;
};

const browserLinkMap: BrowserLinkMap = {
  firefox: 'about:preferences#privacy',
  edge: 'edge://settings/content/notifications',
  chrome: 'chrome://settings/content/notifications',
  safari: 'Safari > Settings > Websites > Notifications',
};

export const getBrowserSpecificMessage = () => {
  const browserName = getBrowser();
  return browserName && browserLinkMap[browserName];
};
