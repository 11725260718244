import { AlarmsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useAlarms(
  searchParams: string,
  refetchInterval: number,
  onSuccess?: (data: AlarmsDTO) => void,
  disabled = false,
  keepPreviousData = false
) {
  const url = `/v2/orm/alarm?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<AlarmsDTO>(request, {
    queryKey: ['alarms', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
    refetchIntervalInBackground: true,
    onSuccess,
    enabled: !disabled,
    keepPreviousData,
  });
}

export default useAlarms;
