import { SOPDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

type Options =
  | {
      alarmId: string;
    }
  | {
      locationId: string;
    };

export function useSOP(opt: Options, onSuccess?: (data: SOPDTO) => void) {
  const search = new URLSearchParams();

  if ('alarmId' in opt) {
    search.set('alarm_id', opt.alarmId);
  } else {
    search.set('location_id', opt.locationId);
  }

  const url = `/v2/orm/sop/find?${search.toString()}`;
  const request = new Request(url);

  return useAuthenticatedRequest<SOPDTO>(request, {
    queryKey: ['sop', opt],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    onSuccess,
  });
}

export default useSOP;
