import { useCanAddCamera } from '@hakimo-ui/hakimo/util';
import { Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { AddCamera } from './add-camera/AddCamera';

export interface Props {
  isLoading: boolean;
  isDefault: boolean;
  onClickOpenFilterPanel: () => void;
}

export function CameraListHeader(props: Props) {
  const { isLoading, onClickOpenFilterPanel, isDefault } = props;
  const [addCamera, setAddCamera] = useState(false);
  const canAddCamera = useCanAddCamera();

  return (
    <>
      <div className="flex items-center justify-end gap-4 py-1 pr-4 pl-1">
        {isLoading && <HakimoSpinner />}
        <Button onClick={() => setAddCamera(true)}>Add Camera</Button>
        <Button
          variant="icon"
          onClick={onClickOpenFilterPanel}
          badge={!isDefault}
        >
          <FunnelIcon className="dark:text-ondark-text-2 h-5 w-5" />
        </Button>
      </div>
      {canAddCamera && addCamera && (
        <AddCamera onClose={() => setAddCamera(false)} />
      )}
    </>
  );
}

export default CameraListHeader;
