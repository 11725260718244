/* eslint-disable max-lines */
import { useAlarms } from '@hakimo-ui/hakimo/data-access';
import { AlarmsDTO } from '@hakimo-ui/hakimo/types';
import { FilterPanel } from '@hakimo-ui/hakimo/ui-filters';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  audioNotifyAtom,
  currentTenantAtom,
  systemNotifyAtom,
  trackFilters,
  useCanViewTenantColumn,
  useLocalStorage,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOnClickRow } from '../shared/hooks/useOnClickRow';
import { notifyNewAlarm } from '../shared/util/common';
import { getSearchParams } from '../shared/util/request-util';
import AlarmListTable from './alarm-list-table/AlarmListTable';
import { ALARM_QUERY_INTERVAL, PAGE_SIZE } from './constants';
import {
  defaultFilterValues,
  getFilterConfigs,
  patchFilterValues,
} from './filter-utils';
import { pageAtom } from './state';
import { FilterValues } from './types';
import { useUpdateRenderedTime } from './useUpdateRenderedTime';
import { columns, getTableData } from './util';
function AlarmList() {
  const currentTenant = useAtomValue(currentTenantAtom);
  const tenants = useMemo(
    () => (currentTenant ? [currentTenant] : []),
    [currentTenant]
  );
  const canViewTenantColumn = useCanViewTenantColumn();
  const [filterValuesAtom] = useState(() =>
    atomWithStorage(
      'rawAlarms/filters',
      defaultFilterValues(canViewTenantColumn)
    )
  );
  const [page, setPage] = useAtom(pageAtom);
  const [filterValues, setFilterValues] = useAtom(filterValuesAtom);
  const [total, setTotal] = useState(0);
  const [queryParams, setQueryParams] = useState<string>(
    getSearchParams(page, PAGE_SIZE, filterValues, tenants)
  );
  const audioNotify = useAtomValue(audioNotifyAtom);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const filterConfigs = getFilterConfigs(canViewTenantColumn);
  const systemNotifyPreference = useAtomValue(systemNotifyAtom);

  const availableColumnIds = useMemo(
    () =>
      columns
        .filter((col) => col.id !== 'tenant' || canViewTenantColumn)
        .map((col) => col.id),
    [canViewTenantColumn]
  );
  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });
  const prevData = useRef<AlarmsDTO>();
  const [shownColumns, setShownColumns] = useLocalStorage<string[]>(
    'alarm-list-shown-columns',
    availableColumnIds
  );

  useEffect(() => {
    setFilterValues((values) => patchFilterValues(values, canViewTenantColumn));
  }, [setFilterValues, canViewTenantColumn]);

  useEffect(() => {
    setQueryParams(getSearchParams(page, PAGE_SIZE, filterValues, tenants));
  }, [page, filterValues, tenants]);

  const { isLoading, isError, error, data, isRefetching } = useAlarms(
    queryParams,
    ALARM_QUERY_INTERVAL
  );

  const onClickRow = useOnClickRow();
  useUpdateRenderedTime(data);

  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      const newData = getTableData(
        items,
        onClickRow,
        canViewTenantColumn,
        shownColumns ?? availableColumnIds
      );
      notifyNewAlarm(
        prevData.current?.items,
        data.items,
        page,
        'alarm',
        audioNotify,
        systemNotifyPreference
      );
      prevData.current = data;
      setTableData(newData);
      setTotal(t);
    }
  }, [
    data,
    onClickRow,
    canViewTenantColumn,
    shownColumns,
    availableColumnIds,
    page,
    audioNotify,
    systemNotifyPreference,
  ]);

  const onApplyFilters = (values: FilterValues) => {
    setFilterValues(values);
    trackFilters(values);
    setOpenFilterPanel(false);
    setPage(1);
  };

  return (
    <Page title="Alarms">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      <FilterPanel
        title="Alarm Filters"
        open={openFilterPanel}
        filterConfigs={filterConfigs}
        initialFilterValues={filterValues}
        trackingEventNameApply="apply_alarms_filters"
        trackingEventNameReset="reset_alarms_filters"
        onClose={() => setOpenFilterPanel(false)}
        onApply={onApplyFilters}
        enableNegativeFilters
      />
      <AlarmListTable
        tableData={tableData}
        canViewTenantColumn={canViewTenantColumn}
        shownColumns={shownColumns ?? availableColumnIds}
        isLoading={(isLoading || isRefetching) && !tableData}
        isRefetching={isRefetching || isLoading}
        total={total}
        page={page}
        pageSize={PAGE_SIZE}
        filterValues={filterValues}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onFirstPage={() => setPage(1)}
        onChangeShownColumns={setShownColumns}
        onOpenFilterPanel={() => setOpenFilterPanel(true)}
      />
    </Page>
  );
}
export default AlarmList;
