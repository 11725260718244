import { useLocationContacts } from '@hakimo-ui/hakimo/data-access';
import { Location } from '@hakimo-ui/hakimo/types';
import { BasicPanel, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import ContactConnect from '../contact-connect/ContactConnect';

interface Props {
  location: Location;
}

function LocationContacts(props: Props) {
  const { location } = props;
  const queryResult = useLocationContacts(location.id);

  return (
    <QueryResult queryResult={queryResult}>
      {(contacts) => {
        return (
          <BasicPanel title="Location Contacts">
            <div className="dark:divide-ondark-line-2 divide-onlight-line-2 divide-y px-6">
              {contacts.map((contact) => (
                <ContactConnect
                  toNumber={contact.phoneNumber}
                  toName={contact.name}
                  key={contact.id}
                />
              ))}
            </div>
          </BasicPanel>
        );
      }}
    </QueryResult>
  );
}

export default LocationContacts;
