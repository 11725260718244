import { SpaceWrapped } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';

interface Props {
  from: number;
  to: number;
  pageSize: number;
  total: number;
  disabled?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onFirst: () => void;
}

export function Pagination(props: Props) {
  const { from, to, total, pageSize, disabled, onNext, onPrevious, onFirst } =
    props;
  const currentPage = Math.ceil((to - 1) / pageSize);

  return (
    <nav
      className="@container flex items-center justify-between"
      aria-label="Pagination"
    >
      <p className="@sm:block hidden text-xs text-gray-500 dark:text-gray-400">
        {total > 0 && (
          <span>
            Showing
            <SpaceWrapped>
              <span className="font-medium">{from}</span>
            </SpaceWrapped>
            to
            <SpaceWrapped>
              <span className="font-medium">{to}</span>
            </SpaceWrapped>
            of
          </span>
        )}
        <SpaceWrapped>
          <span className="font-medium">{total}</span>
        </SpaceWrapped>
        results
      </p>
      <p className="@sm:hidden block text-xs text-gray-500 dark:text-gray-400">
        {from}-{to} of {total}
      </p>
      <div className="flex flex-1 justify-between sm:justify-end">
        {currentPage > 3 && (
          <div className="mt-1.5 mr-2">
            <Button
              variant="link"
              onClick={onFirst}
              onSideEffect={eventTracker('move_first_page')}
            >
              1st Page
            </Button>
          </div>
        )}
        <div>
          <Button
            onClick={onPrevious}
            disabled={disabled || total === 0 || from === 1}
            onSideEffect={eventTracker('move_previous_page')}
          >
            <span className="@xs:block hidden">Previous</span>
            <span className="@xs:hidden block">&lt;</span>
          </Button>
        </div>
        <div className="ml-3">
          <Button
            onClick={onNext}
            disabled={disabled || total === 0 || to === total}
            onSideEffect={eventTracker('move_next_page')}
          >
            <span className="@xs:block hidden">Next</span>
            <span className="@xs:hidden block">&gt;</span>
          </Button>
        </div>
      </div>
    </nav>
  );
}

export default Pagination;
