import {
  Engine,
  OutputFormat,
  PollyClient,
  SynthesizeSpeechCommand,
  TextType,
  VoiceId,
} from '@aws-sdk/client-polly';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { useEffect, useState } from 'react';
import { streamToBlob } from './util';

export function useTextToSpeechSDK(region: string, identityPoolId: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<PollyClient>();

  useEffect(() => {
    setClient(
      new PollyClient({
        region,
        credentials: fromCognitoIdentityPool({
          clientConfig: { region },
          identityPoolId,
        }),
      })
    );
  }, [identityPoolId, region]);

  const fetchAudio = async (text: string) => {
    try {
      if (!client) {
        return new Error('Polly client not initialized yet');
      }
      setIsLoading(true);
      const params = {
        Engine: Engine.NEURAL,
        Text: text,
        OutputFormat: OutputFormat.MP3,
        VoiceId: VoiceId.Matthew,
        TextType: TextType.SSML,
      };
      const command = new SynthesizeSpeechCommand(params);
      const response = await client.send(command);
      if (response.AudioStream) {
        const audioStream = response.AudioStream;
        const audioBlob = await streamToBlob(
          audioStream as ReadableStream<Uint8Array>
        );
        const audioUrl = URL.createObjectURL(audioBlob);
        const file = new File([audioBlob], Date.now() + '.mpeg');
        return { audioUrl, file };
      }
      return new Error('Failed to fetch audio file');
    } catch (e) {
      return new Error('Failed to fetch audio file');
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchAudio, isLoading, isClientReady: client !== undefined };
}

export default useTextToSpeechSDK;
